import { colorChangeSampleService, materialListService, minePrepressChangeColorTaskService } from '@/api';
import {} from '@/resource/model';
import { TagsViewModule } from '@/store/modules/tags-view';
import {
  decimalToNumber6,
  isNullOrUndefinedForBaseType,
  limitFutureForTimePicker,
  messageError,
  translation
} from '@/utils';
import { Message } from 'element-ui';
// import { ElForm } from 'element-ui/types/form';
import { Component, ProvideReactive, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router/types/router';
import { NormalSelectOptions } from '@/resource/model/common';
import { ColorChangeSample } from '@/resource/model/prepress-task/prepress-task';
import { PrepressChangeColorCraftsEnum } from '@/resource/enum';
import { ElForm } from 'element-ui/types/form';
import Decimal from 'decimal.js';
Component.registerHooks(['beforeRouteLeave']);
@Component({
  name: 'AddChangeColorSample',
  components: {}
})
export default class AddChangeColorSample extends Vue {
  /**
   *  印前任务ID
   */
  public id = Number(this.$route.query.id);

  public type: 'add' | 'edit' = this.$route.query.type as 'add' | 'edit';

  public form: Partial<ColorChangeSample> = {
    id: null,
    itemCode: '',
    itemId: 0,
    /**
     * 后道工艺
     */
    backendCrafts: '',
    /**
     * 后道流程 1 制作 0 不制作
     */
    backendFlag: 0,
    /**
     * 项次数量
     */
    count: 0,
    /**
     * 客户产品id
     */
    customerProductId: 0,
    /**
     * 客户产品名称
     */
    customerProductName: '',
    /**
     * 完成高
     */
    finishHeight: 0,
    /**
     * 完成宽
     */
    finishWidth: 0,
    /**
     * 单据类型
     */
    itemType: 0,
    /**
     * 制作品名id
     */
    platformProductId: 0,
    /**
     * 制作品名
     */
    platformProductName: '',
    /**
     * 印前工艺
     */
    prepressCrafts: 0,
    /**
     * 印前画面
     */
    prepressPicture: '',
    /**
     * 印前说明
     */
    prepressRemark: '',
    /**
     * 输出流程 1 制作 0 不制作
     */
    printingFlag: 0,
    /**
     * 项目编号
     */
    projectCode: '',
    /**
     * 项目名称
     */
    projectName: '',
    /**
     * 备注
     */
    remark: '',
    /**
     * 要求出货时间
     */
    requiredDeliveryTime: '',
    /**
     * 可见高
     */
    visibleHeight: 0,
    /**
     * 可见宽
     */
    visibleWidth: 0,

    totalArea: 0,

    unitArea: 0
  };

  public formRules: { [prop in keyof Partial<ColorChangeSample>]: Array<Object> } = {
    count: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (isNullOrUndefinedForBaseType(value)) {
            callback(new Error(translation('orderChange.productCountInputTip')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    finishHeight: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (isNullOrUndefinedForBaseType(value)) {
            callback(new Error(translation('customerPoint.inputFinishHeight')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ],
    finishWidth: [
      {
        required: true,
        validator: (rule: any, value: number, callback: Function): void => {
          if (isNullOrUndefinedForBaseType(value)) {
            callback(new Error(translation('customerPoint.inputFinishWidth')));
            return;
          }
          callback();
        },
        trigger: 'blur'
      }
    ]
  };

  public limitDateRangeOption = {
    disabledDate: limitFutureForTimePicker
  };

  public tagList: NormalSelectOptions = [];

  public selectEmployeeVisible = false;

  public saveLoading = false;

  @ProvideReactive()
  public shopId = 0;

  /**
   * 单位面积计算
   *【单位面积】=【完成宽度】*【完成高度】/1000000
   */
  public calculateUnitArea(): void {
    this.form.unitArea =
      decimalToNumber6(
        new Decimal(this.form.finishWidth as number).mul(new Decimal(this.form.finishHeight as number))
      ) / 1000000 || 0;
    this.calculateTotalArea();
  }
  /**
   * 面积计算
   * 【合计面积】=【单位面积】*【销售数量】
   */
  public calculateTotalArea(): void {
    this.form.totalArea = decimalToNumber6(
      new Decimal(this.form.unitArea as number).mul(new Decimal(this.form.count as number))
    );
  }

  public get screenRate(): string {
    return (this.form!.visibleWidth! / this.form!.visibleHeight!).toFixed(3);
  }

  public get prepressCrafts(): string {
    return translation(`prepressChangeColorCrafts.${PrepressChangeColorCraftsEnum[this.form!.prepressCrafts!]}`);
  }

  public activated(): void {
    // 当id变化时，重新加载数据
    if (!isNaN(this.id) && this.id !== Number(this.$route.query.id)) {
      this.id = Number(this.$route.query.id);
      this.type = this.$route.query.type as 'add' | 'edit';

      if (['add'].includes(this.type)) {
        this.getPrepressOrderInfo(this.id);
      } else if (['edit', 'copy'].includes(this.type)) {
        this.getChangeColorSampleInfo(this.id);
      }
    }
  }

  public created(): void {
    if (!isNaN(this.id)) {
      if (['add'].includes(this.type)) {
        this.getPrepressOrderInfo(this.id);
      } else if (['edit', 'copy'].includes(this.type)) {
        this.getChangeColorSampleInfo(this.id);
      }
    }
  }

  public beforeRouteLeave(to: Route, from: Route, next: Function): void {
    // if (to.path === '/installation-order') {
    //   to.meta!.needReload = this.needReloadList;
    // }
    TagsViewModule.DelView(this.$route);
    next();
  }

  public async save(): Promise<void> {
    (this.$refs.formRefs as ElForm).validate(async (valid: boolean) => {
      if (!valid) {
        return;
      }
      this.setFinishWidth();
      this.setFinishHeight();
      try {
        if (['add', 'copy'].includes(this.type)) {
          await colorChangeSampleService.post([this.form as ColorChangeSample]);
          Message.success(translation('operationRes.addSuccess'));
        } else {
          await colorChangeSampleService.put(this.form as ColorChangeSample);
          Message.success(translation('operationRes.editSuccess'));
        }
        this.$router.go(-1);
      } catch (error) {
        if (error) {
          messageError(error as any);
        }
      }
    });
  }

  private getPrepressOrderInfo(id: number): void {
    minePrepressChangeColorTaskService
      .getItemInfo(id)
      .then(res => {
        Object.assign(this.form, res);
        this.form.id = null;
        this.form.itemId = res.id;
        this.form.customerName = this.$route.query.customerName as string;
        this.calculateUnitArea();
        this.getProductionInfo(res.platformProductId!);
      })
      .catch(error => {
        messageError(error);
      });
  }
  private getChangeColorSampleInfo(id: number): void {
    colorChangeSampleService
      .getById(id)
      .then(res => {
        Object.assign(this.form, res);
      })
      .catch(error => {
        messageError(error);
      });
  }

  private async getProductionInfo(id: number): Promise<void> {
    const list = await materialListService.getListForProductionComponents([id]);
    const { printingFlag, backendFlag } = list[0];
    console.log('开关', printingFlag, backendFlag);
    this.form.backendFlag = backendFlag;
    this.form.printingFlag = printingFlag;
  }
  @Watch('form.count', { immediate: true })
  private setCount(val: number): void {
    this.form.count = Number(parseInt(val.toString(), 10));
    if (this.form.count < 1) {
      this.form.count = 1;
    }
  }

  private setFinishWidth(): void {
    this.form.finishWidth = Number(parseInt(this.form!.finishWidth!.toString(), 10));
    if (this.form.finishWidth < 1) {
      this.form.finishWidth = 1;
    }
    this.form.visibleWidth = this.form.finishWidth;
    this.calculateUnitArea();
  }
  private setFinishHeight(): void {
    this.form.finishHeight = Number(parseInt(this.form!.finishHeight!.toString(), 10));
    if (this.form.finishHeight < 1) {
      this.form.finishHeight = 1;
    }
    this.form.visibleHeight = this.form.finishHeight;

    this.calculateUnitArea();
  }
}
